<template>
  <v-app>
    <v-main>
      <v-container>
        <v-card>
          <v-card-title> 刷仓管理器 </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle>开始新的任务</v-card-subtitle>
          <v-container>
            <v-text-field label="刷仓链接" v-model="link"></v-text-field>
            <v-btn block color="primary" @click="submit" :disabled="disable_btn"
              >提交</v-btn
            >
          </v-container>
          <v-divider></v-divider>
          <v-card-subtitle>正在进行的任务</v-card-subtitle>
          <v-card-text v-if="inProgressWorks.length == 0"
            >暂无正在进行的任务</v-card-text
          >
          <v-container v-for="work in inProgressWorks" :key="work.id">
            <v-card :color="work.done ? 'light-green' : 'yellow'">
              <v-card-title>ID为{{ work.id }}的任务</v-card-title>
              <v-card-text
                >状态:{{ work.done ? "完成" : "正在进行" }}</v-card-text
              >
            </v-card>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Axios from "axios";
export default {
  name: "App",

  components: {},

  data: () => ({
    inProgressWorks: [],
    link: "",
    disable_btn: false,
  }),

  methods: {
    refresh: function () {
      Axios.post("api/refresh")
        .then((resp) => {
          this.inProgressWorks = resp.data == null ? [] : resp.data;
        })
        .finally(() => {});
    },
    submit: function () {
      this.disable_btn = true;
      Axios.post("api/submit", { link: this.link }).finally(() => {
        this.disable_btn = false;
        this.link = "";
        this.refresh();
      });
    },
  },

  mounted: function () {
    this.refresh()
    setInterval(() => {
      this.refresh();
    }, 5000);
  },
};
</script>
